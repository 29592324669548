import React, { useState, useEffect } from 'react';
import { getWithdrawalPsbt, getWithdrawals } from './lib/tenguClient';
import { Alert, Button, ButtonGroup, Container, Table } from 'react-bootstrap';

interface IData {
  id: string;
  walletId: string;
  address: string;
  amount: number;
  fee: number;
  txId: string;
  psbt: string;
  metaData: any;
  createdAt: string;
  broadcasted: boolean;
  broadcastedAt: string;
  confirmed: boolean;
  confirmedAt: string;
  wallet: {
    walletType: string;
  }
}

const AdminDashboard = () => {
  const [data, setData] = useState<IData[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Fetch data from JSON RPC API
  useEffect(() => {
    getWithdrawals()
      .then((response) => {
        console.log('getWithdrawals response', response);
        setData(response.result);
      })
      .catch((error) => {
        if (error.message === 'Token expired') {
          window.location.replace('/login');
        }
        setError(error.message);
        console.error(error);
      });
  }, []);

  const base64ToUint8Array = (base64: string) => {
    try {
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    } catch (error) {
      console.debug(base64);
      console.error('Failed to decode base64 string:', error);
      return false;
    }
  };

  const downloadPsbt = (base64data: string, filename: string) => {
    const decodedData = base64ToUint8Array(base64data);

    if (!decodedData) {
      setError('Failed to get PSBT');
      return;
    }

    // Create a Blob from the response result
    const blob = new Blob([decodedData], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  const getPsbt = async (row: IData) => {
    if (row.psbt) {
      downloadPsbt(row.psbt, `${row.id}.psbt`);
    } else {
      getWithdrawalPsbt(row.id, row.walletId)
      .then((response) => {
        console.log('getPsbt response', response);

        const item = data?.find((item) => item.id === row.id)
        if (item) item.psbt = response.result;

        downloadPsbt(response.result, `${row.id}.psbt`);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      });
    }
  }

  const getSignerLabel = (signer: string) => {
    switch (signer) {
      case 'baselayer':
        return 'Base Layer Labs';
      case 'btcadviser':
        return 'BTC Adviser';
      case 'hot':
        return 'Hot Key';
      case 'user1':
        return 'User Key #1';
      case 'user2':
        return 'User Key #2';
      default:
        return signer;
    }
  }

  const displaySigners = (row: IData) => {
    if (row.wallet.walletType === 'Collaborative') {
      try {
        const metaData = row.metaData 
        if (!metaData || !metaData.signers) return '';
        const labels = metaData.signers.map((signer: string) => getSignerLabel(signer));
        return ` (${labels.join(', ')})`;
      } catch (error) {
        console.error('Failed to parse metadata:', error);
        return '';
      }
    }

    return '';
  }

  const getMempoolLink = ({ address, tx }: { address?: string, tx?: string }) => {
    if (address) {
      return `https://mempool.space/testnet/address/${address}`;
    }
    if (tx) {
      return `https://mempool.space/testnet/tx/${tx}`;
    }
    return '';
  }

  const getStatus = (row: IData) => {
    if (!row.txId) return 'Requested';
    if (!row.broadcastedAt) return 'Waiting approval';
    if (row.confirmed) return 'Confirmed';
    return 'Broadcasted';
  }

  const satsToBtc = (sats: number | null) => {
    if (!sats) return '';
    return (sats / 100000000).toFixed(8);
  }

  const getActions = (row: IData) => {
    if (!row.broadcasted) {
      return (
        <ButtonGroup className="mb-3">
          <Button variant="secondary" size="sm" className="text-nowrap" onClick={() => getPsbt(row)}>Get PSBT</Button>
          <Button variant="secondary" size="sm" className="text-nowrap">Update PSBT</Button>
        </ButtonGroup>
      );
    }

    return (<></>)
  }

  return (
    <Container fluid className="mt-5">
      <h1 className="mb-4">Withdrawal Requests</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {data && data.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Status</th>
              <th>Wallet type</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Fee</th>
              <th>Created</th>
              <th>Broadcasted</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <React.Fragment key={item.id}>
                <tr className="table-group-divider">
                  <td>
                    {getStatus(item)}
                    {displaySigners(item) && (<><br /><b>Signers:</b>{displaySigners(item)}</>)}
                    </td>
                  <td>{item.wallet.walletType}</td>
                  <td>
                    <a href={getMempoolLink({ address: item.address })} target="_blank" rel="noopener noreferrer">
                      {item.address}
                    </a>
                  </td>
                  <td>{satsToBtc(item.amount)}</td>
                  <td>{satsToBtc(item.fee)}</td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>{item.broadcastedAt ? new Date(item.broadcastedAt).toLocaleString() : ''}</td>
                  <td>{getActions(item)}</td>
                </tr>
                {item.txId && (
                  <tr className="table-group-divider">
                    <td colSpan={8}>
                      <a href={getMempoolLink({ tx: item.txId })} target="_blank" rel="noopener noreferrer">
                        {item.txId}
                      </a>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">No data found.</Alert>
      )}
    </Container>
  );
};

export default AdminDashboard;