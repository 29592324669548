import axios, { AxiosRequestConfig } from 'axios';
import Config from './config';

const API_ENDPOINT = Config.apiUrl;

const instance = axios.create({
  baseURL: API_ENDPOINT,
  timeout: 10000,
  // other custom settings
});

//const setToken = (newToken: string, expiresIn: number, newRefreshToken: string) => {
  const setToken = (newToken: string, expiresIn: number) => {
  localStorage.setItem('token', newToken);
  localStorage.setItem('tokenExpiry', (Date.now() + expiresIn * 1000).toString());
  //localStorage.setItem('refreshToken', newRefreshToken);
};

const getToken = () => localStorage.getItem('token');
const getTokenExpiry = () => {
  const expiry = localStorage.getItem('tokenExpiry');
  return expiry ? parseInt(expiry, 10) : null;
};
//const getRefreshToken = () => localStorage.getItem('refreshToken');

const isTokenExpired = () => {
  const tokenExpiry = getTokenExpiry();
  if (!tokenExpiry) return true;
  return Date.now() >= tokenExpiry;
};

// const refreshAuthToken = async () => {
//   const refreshToken = getRefreshToken();
//   if (!refreshToken) throw new Error('No refresh token available');

//   const response = await authRefresh(refreshToken);
//   setToken(response.accessToken, response.expiresIn, response.refreshToken);
// };

const rpcCall = async (method: string, params: any, auth: boolean = true) => {
  if (auth && isTokenExpired()) {
    //await refreshAuthToken();
    throw new Error('Token expired');
  }

  const config: AxiosRequestConfig = {};
  const token = getToken();
  if (auth && token) {
    config.headers = { Authorization: `Bearer ${token}` };
  }

  const response = await instance.post(
    '/jsonrpc',
    {
      jsonrpc: '2.0',
      id: method,
      method,
      params,
    },
    config,
  );

  console.log('rpcCall success', response.data);

  return response.data;
};

const auth = async (username: string, password: string) => {
  try {
    const response = await instance.post('/auth', {
      type: 'admin',
      username,
      password,
    });

    console.log('auth call success', response.data);
    setToken(response.data.token, 1800); //response.data.expiresIn, response.data.refreshToken);

    return response.data;
  } catch (err: any) {
    console.log('auth error', err);

    return err.response;
  }
};

/*const authRefresh = async (refreshToken: string) => {
  console.log('calling authRefresh', refreshToken);

  try {
    const response = await instance.post('/auth/refresh', {
      refreshToken,
    });

    console.log('authRefresh call success', response.data);

    return response.data;
  } catch (err: any) {
    console.log('authRefresh error', err);
    return err.response.data;
  }
}*/

const getUsers = async () => {
  console.log('calling getUsers');

  const response = await rpcCall('getUsers', { });

  return response;
};

const getWallets = async () => {
  console.log('calling getWallets');

  const response = await rpcCall('getWallets', {});

  return response;
};

const getWallet = async (walletId: string) => {
  console.log('calling getWallet', walletId);

  const response = await rpcCall('getWallet', { id: walletId });

  return response;
};

const getNewAddress = async (walletId: string) => {
  console.log('calling getNewAddress', walletId);

  const response = await rpcCall('getNewAddress', { walletId });

  return response;
};

const getUtxos = async (walletId: string) => {
  console.log('calling getUtxos', walletId);

  const response = await rpcCall('getUtxos', { walletId });

  return response;
};

const getTransactions = async (walletId: string) => {
  console.log('calling getTransactions', walletId);

  const response = await rpcCall('getTransactions', { walletId });

  return response;
};

const createWithdrawal = async (
  walletId: string,
  address: string,
  amount: number,
) => {
  console.log('calling createWithdrawal', walletId, address, amount);

  const response = await rpcCall('createWithdrawal', {
    walletId,
    address,
    amount,
  });

  return response;
};

const getWithdrawals = async () => {
  console.log('calling getWithdrawals');

  const response = await rpcCall('adminGetWithdrawals', { });

  return response;
};

const getWithdrawalPsbt = async (withdrawalId: string, walletId: string) => {
  console.log('calling getWithdrawalPsbt', withdrawalId, walletId);

  const response = await rpcCall('adminGetWithdrawalPsbt', { withdrawalId, walletId });

  return response;
}

export {
  auth,
  getUsers,
  getWallets,
  getWallet,
  getNewAddress,
  getUtxos,
  getTransactions,
  createWithdrawal,
  getWithdrawals,
  getWithdrawalPsbt,
};
