import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { auth } from './lib/tenguClient';

interface ILoginForm {
  username: string;
  password: string;
}

const Login = () => {
  const [form, setForm] = useState<ILoginForm>({
    username: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);

  // Handle login submission
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    auth(form.username, form.password)
      .then((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token);
          window.location.replace('/');
        } else if (response.error) {
          setError(response.error);
        } else if (response.data && response.data.error) {
          setError(response.data.error);
        } else {
          setError('An unknown error occurred');
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-4">Login</h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username" className="md-form">
                  <Form.Label>Username:</Form.Label>
                  <Form.Control
                    type="text"
                    value={form.username}
                    onChange={(event) => setForm({ ...form, username: event.target.value })}
                  />
                </Form.Group>
                <Form.Group controlId="password" className="md-form">
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    value={form.password}
                    onChange={(event) => setForm({ ...form, password: event.target.value })}
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Login
                </Button>
              </Form>
              {error && <Alert variant="danger" className="mt-4">{error}</Alert>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;