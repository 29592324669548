export interface Config {
  httpPort: number;
  apiUrl: string;
}

const API_URL= "https://tsapi.tail181ebc.ts.net";

const config: Config = {
  httpPort: !isNaN(Number(process.env.HTTP_PORT))
    ? Number(process.env.HTTP_PORT)
    : 3001,
  apiUrl: process.env.API_URL || API_URL || 'http://localhost:3000',
};

export default config;
