import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';

type ProtectedRouteProps = RouteProps & {
  component: React.ComponentType<any>;
};

const ProtectedRoute = ({ component: Component, ...rest }: ProtectedRouteProps) => {
  const accessToken = localStorage.getItem('token');

  return accessToken ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;